<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div v-if="isEditMode">
      <h2 class="my-4 text-4xl font-semibold">Unit Info</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Edit Unit</p>
      </div>
    </div>

    <div v-else>
      <h2 class="my-4 text-4xl font-semibold">Add Unit</h2>

      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Add a unit to the database</p>
      </div>
    </div>

    <!-- Unit Content -->

    <div class="w-full lg:w-1/3 mt-5">
      <label class="w-full">Unit Status</label>
      <select
        v-model="form.status"
        class="lb-admin-form-input-select mt-2 rounded"
      >
        <option value="available">Available</option>
        <option value="pending">Pending</option>
        <option value="reserved">Reserved</option>
        <option value="sold">Sold</option>
      </select>
    </div>

    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/3 mt-5">
        <label for="admin-unit-name" class="w-full">Unit Number</label>
        <input
          v-model="form.name"
          id="admin-unit-name"
          class="lb-admin-form-input-select rounded"
          placeholder="e.g. 1A (letter last)"
          type="text"
        />
      </div>

      <div class="fields-flex w-full lg:w-1/3 mt-5">
        <label class="flex align-center mr-2">Custom Id:</label>
        <input
          v-model="form.customIdViewButton"
          class="lb-admin-form-input-select rounded w-full"
          type="text"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Unit Type</label>
        <div v-if="unitTypes">
          <select
            v-model="form.unitType"
            class="lb-admin-form-input-select rounded"
          >
            <option
              v-for="unitType in unitTypeList"
              :key="unitType"
              :value="unitType"
            >
              {{unitType}}
            </option>
          </select>
        </div>
        <div v-else>
          <select v-model="form.unitType" class="lb-admin-form-input-select rounded">
            <option
              value="1 Bed 1 Bath"
            >
              1 Bed 1 Bath
            </option>
            <option
              value="2 Bed 1 Bath"
            >
              2 Bed 1 Bath
            </option>
            <option
              value="2 Bed 2 Bath"
            >
              2 Bed 2 Bath
            </option>
            <option
              value="3 Bed 1 Bath"
            >
              3 Bed 1 Bath
            </option>
            <option
              value="Ground Floor Studio"
            >
              Ground Floor Studio
            </option>
            <option
              value="Shared Studio"
            >
              Shared Studio
            </option>
            <option
              value="Studio"
            >
              Studio
            </option>
            <option
              value="Studio with Balcony A"
            >
              Studio with Balcony A
            </option>
            <option
              value="Studio with Balcony B"
            >
              Studio with Balcony B
            </option>
            <option
              value="Studio with Balcony C"
            >
              Studio with Balcony C
            </option>
            <option
              value="Studio with Patio"
            >
              Studio with Patio
            </option>
          </select>
        </div>

      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Unit Price</label>
        <input
          v-model="form.unitPrice"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Levies</label>
        <input
          v-model="form.unitLevies"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Rates</label>
        <input
          v-model="form.unitRates"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Est. Rental</label>
        <input
          v-model="form.unitGuaranteedRental"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <div class="fields-flex w-full lg:w-1/3 mt-5">
        <div v-if="form.isGuaranteedRental ">
          <div class="flex align-center">
            <label class="flex align-center mr-2">Guaranteed Rental:</label>
            <o-switch v-model="form.isGuaranteedRental" :required="false"></o-switch>
          </div>
          <input
            v-model="form.unitRentalGuaranteed"
            class="lb-admin-form-input-select rounded w-full"
            type="text"
          />
        </div>
        <div v-else class="flex align-center mt-5">
          <label class="flex align-center mr-2">Guaranteed Rental:</label>
          <o-switch v-model="form.isGuaranteedRental" :required="false"></o-switch>
        </div>
      </div>

      <div class="fields-flex w-full lg:w-1/3 mt-5">
        <div v-if="form.isOverrideAction ">
          <div class="flex align-center">
            <label class="flex align-center mr-2">Override Action:</label>
            <o-switch v-model="form.isOverrideAction" :required="false"></o-switch>
          </div>
          <input
            v-model="form.unitOverrideAction"
            class="lb-admin-form-input-select rounded w-full"
            type="text"
            placeholder="URL: https://example.co.za/"
          />
        </div>
        <div v-else class="flex align-center mt-5">
          <label class="flex align-center mr-2">Override Action:</label>
          <o-switch v-model="form.isOverrideAction" :required="false"></o-switch>
        </div>
      </div>

      <div
        v-if="form.isOverrideAction"
        class="fields-flex w-full lg:w-1/3 mt-5"
      >
        <label class="flex align-center mr-2">Override Action Button:</label>
        <input
          v-model="form.unitOverrideActionButton"
          class="lb-admin-form-input-select rounded w-full"
          type="text"
        />
      </div>

      <ul
        v-for="global in globals"
        :key="global.setOneDayOnlyDiscount"
        class="fields-flex w-full lg:w-1/3 mt-5"
      >
        <div v-if="global.setOneDayOnlyDiscount" >
          <div v-if="form.isOneDayOnlyDiscount">
            <div class="flex align-center">
              <label class="flex align-center mr-2">OneDayOnly Discount:</label>
              <o-switch v-model="form.isOneDayOnlyDiscount" :required="false"></o-switch>
            </div>
            <input
              v-model="form.oneDayOnlyDiscount"
              class="lb-admin-form-input-select rounded w-full"
              type="number"
            />
          </div>
          <div v-else class="flex align-center mt-5">
            <label class="flex align-center mr-2">OneDayOnly Discount:</label>
            <o-switch v-model="form.isOneDayOnlyDiscount" :required="false"></o-switch>
          </div>
        </div>
      </ul>

      <div
        v-if="form.isOneDayOnlyDiscount"
        class="fields-flex w-full lg:w-1/3 mt-5"
      >
        <label class="flex align-center mr-2">OneDayOnly Start Date:</label>
        <o-datetimepicker
          :required="false"
          v-model="form.oneDayOnlyDiscountDateStart"
          placeholder="Click to select..."
          class="w-full rounded"
          override
        >
          <div class="buttons-footer w-full flex justify-between mb-2 px-2">
            <o-button
              :required="false"
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="form.oneDayOnlyDiscountDateStart = new Date()"
            >
              <o-icon icon="calendar"></o-icon>
              <span>Today</span>
            </o-button>
            <o-button
              :required="false"
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="form.oneDayOnlyDiscountDateStart = null"
            >
              <o-icon :required="false" icon="times"></o-icon>
              <span>Clear</span>
            </o-button>
          </div>
        </o-datetimepicker>
      </div>

      <div
        v-if="form.isOneDayOnlyDiscount"
        class="fields-flex w-full lg:w-1/3 mt-5"
      >
        <label class="flex align-center mr-2">OneDayOnly Finish Date:</label>
        <o-datetimepicker
          :required="false"
          v-model="form.oneDayOnlyDiscountDateFinish"
          placeholder="Click to select..."
          class="w-full rounded"
          override
        >
          <div class="buttons-footer w-full flex justify-between mb-2 px-2">
            <o-button
              :required="false"
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="form.oneDayOnlyDiscountDateFinish = new Date()"
            >
              <o-icon :required="false" icon="calendar"></o-icon>
              <span>Today</span>
            </o-button>
            <o-button
              :required="false"
              class="bg-primary px-4 py-2 text-white font-semibold rounded"
              @click="form.oneDayOnlyDiscountDateFinish = null"
            >
              <o-icon :required="false" icon="times"></o-icon>
              <span>Clear</span>
            </o-button>
          </div>
        </o-datetimepicker>
      </div>

      <!-- Unit Spec Content -->
      <p class="w-full mt-5 pt-5 text-lg font-bold border-t">
        Unit Specifications
      </p>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Floor</label>
        <select v-model="form.unitFloor" class="lb-admin-form-input-select rounded">
          <option
            value="Ground"
          >
            Ground
          </option>
          <option
            value="1st"
          >
            1st
          </option>
          <option
            value="2nd"
          >
            2nd
          </option>
          <option
            value="3rd"
          >
            3rd
          </option>
<!--          <option-->
<!--            value="4th"-->
<!--          >-->
<!--            4th-->
<!--          </option>-->
<!--          <option-->
<!--            value="5th"-->
<!--          >-->
<!--            5th-->
<!--          </option>-->
<!--          <option-->
<!--            value="6th"-->
<!--          >-->
<!--            6th-->
<!--          </option>-->
<!--          <option-->
<!--            value="7th"-->
<!--          >-->
<!--            7th-->
<!--          </option>-->
<!--          <option-->
<!--            value="8th"-->
<!--          >-->
<!--            8th-->
<!--          </option>-->
<!--          <option-->
<!--            value="9th"-->
<!--          >-->
<!--            9th-->
<!--          </option>-->
<!--          <option-->
<!--            value="10th"-->
<!--          >-->
<!--            10th-->
<!--          </option>-->
<!--          <option-->
<!--            value="11th"-->
<!--          >-->
<!--            11th-->
<!--          </option>-->
<!--          <option-->
<!--            value="12th"-->
<!--          >-->
<!--            12th-->
<!--          </option>-->
<!--          <option-->
<!--            value="13th"-->
<!--          >-->
<!--            13th-->
<!--          </option>-->
<!--          <option-->
<!--            value="14th"-->
<!--          >-->
<!--            14th-->
<!--          </option>-->
<!--          <option-->
<!--            value="15th"-->
<!--          >-->
<!--            15th-->
<!--          </option>-->
        </select>
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Bedrooms</label>
        <input
          v-model="form.unitBedrooms"
          class="lb-admin-form-input-select rounded"
          placeholder="If none then insert 0"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Bathrooms</label>
        <input
          v-model="form.unitBathrooms"
          class="lb-admin-form-input-select rounded"
          placeholder="If none then insert 0"
          type="number"
        />
      </div>

      <div
          class="w-full lg:w-1/3 mt-5"
      >
        <label class="w-full">Parking Bay Price</label>
        <input
            v-model="unitParkingBay"
            class="lb-admin-form-input-select rounded"
            placeholder="If none then insert 0"
            type="number"
        />
      </div>

      <div
        v-if="title === 'The Carrington'"
        class="w-full lg:w-1/3 mt-5"
      >
        <label class="w-full">Storeroom</label>
        <input
          v-model="form.unitStoreroom"
          class="lb-admin-form-input-select rounded"
          placeholder="If none then insert 0"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Direction</label>
        <select v-model="form.unitAspect" class="lb-admin-form-input-select rounded">
<!--          <option value="North">North</option>-->
          <option value="North East">North East</option>
          <option value="North East Cnr">North East Cnr</option>
          <option value="North West Cnr">North West Cnr</option>
          <option value="East">East</option>
          <option value="West">West</option>
<!--          <option value="South">South</option>-->
          <option value="South East Cnr">South East Cnr</option>
          <option value="South West Cnr">South West Cnr</option>
        </select>
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Outlook</label>
        <select v-model="form.unitView" class="lb-admin-form-input-select rounded">
<!--          <option value="Main Courtyard">Main Courtyard</option>-->
<!--          <option value="">Zen Courtyard</option>-->
          <!--          <option value="Paul Kruger St">Paul Kruger St</option>-->
          <!--          <option value="Dennesig St">Dennesig St</option>-->
          <!--          <option value="Hofman St">Hofman St</option>-->
          <!--          <option value="Neighbouring Properties">Neighbouring Properties</option>-->
<!--          <option value="Outward Facing">Outward Facing</option>-->
          <option value="Hottentots-Holland">Hottentots-Holland</option>
          <option value="Table Mountain">Table Mountain</option>
        </select>
      </div>

      <div
          class="w-full lg:w-1/3 mt-5"
      >
        <label class="w-full">Guaranteed Rental Price</label>
        <span
            class="lb-admin-form-input-select rounded font-semibold total-price-container"
            type="number"
            disabled="true"
        >
          {{ unitGuaranteedRentalPrice | prettyCurrency }}
        </span>
      </div>

      <div
        class="w-full lg:w-1/3 mt-5"
      >
        <label class="flex align-center mr-2">Aircon:</label>
        <o-switch v-model="form.isUnitAircon"></o-switch>
      </div>

      <!-- Unit Dimensions Content -->
      <p class="w-full mt-5 pt-5 text-lg font-bold border-t">Unit Dimensions</p>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Internal Area</label>
        <input
          v-model="form.unitInternalArea"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">External Area</label>
        <input
          v-model="form.unitExternalArea"
          class="lb-admin-form-input-select rounded"
          placeholder="If none then insert 0"
          type="number"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Total Area</label>
        <input
          v-model="form.unitTotalArea"
          class="lb-admin-form-input-select rounded"
          type="number"
        />
      </div>

      <!-- Unit Media Content -->
      <p class="w-full mt-5 pt-5 text-lg font-bold border-t">Media</p>

      <!-- Slider 1 - Main image -->
      <div class="w-full xl:w-1/3 flex flex-col mb-5 px-3">
        <p class="mt-3">Main Image</p>
        <p class="text-sm">
          Image will display on the unit card and will be the first image in the
          unit slider
        </p>
        <p class="mt-1 mb-5 text-xs font-semibold">
          Images must be 1280px × 853px
        </p>

        <div
          v-if="form.mainImageURL !== null"
          class="w-72 object-contain relative"
        >
          <img
            class="object-cover"
            height="268"
            width="356"
            :src="form.mainImageURL"
          />
          <button
            class="absolute -top-3 -right-3 lb-dialog-close-btn-x text-sm"
            @click.prevent="form.mainImageURL = null"
          >
            x
          </button>
        </div>

        <div v-else class="h-48 w-72 flex items-center justify-center border">
          <span class="flex flex-col items-center">
            <ImageIcon />
            No Image
          </span>
        </div>

        <input
          type="file"
          class="mt-5"
          @change="previewMainImage"
          accept="image/*"
        />
      </div>

      <!-- Slider 2 -->
      <div class="w-full xl:w-1/3 flex flex-col mb-5 px-3">
        <p class="mt-3">Slider 2</p>
        <p class="text-sm">
          Image will display as the second image in the unit slider
        </p>
        <p class="mt-1 mb-5 text-xs font-semibold">
          Images must be 1280px × 853px
        </p>

        <div
          v-if="form.slide2ImageURL !== null"
          class="w-72 object-contain relative"
        >
          <img
            class="object-cover"
            height="268"
            width="356"
            :src="form.slide2ImageURL"
          />
          <button
            class="absolute -top-3 -right-3 lb-dialog-close-btn-x text-sm"
            @click.prevent="form.slide2ImageURL = null"
          >
            x
          </button>
        </div>

        <div v-else class="h-48 w-72 flex items-center justify-center border">
          <span class="flex flex-col items-center">
            <ImageIcon />
            No Image
          </span>
        </div>

        <input
          type="file"
          class="mt-5"
          @change="previewSlider2Image"
          accept="image/*"
        />
      </div>

      <!-- Slider 3 -->
      <div class="w-full xl:w-1/3 flex flex-col mb-5 px-3">
        <p class="mt-3">Slider 3</p>
        <p class="text-sm">
          Image will display as the third image in the unit slider
        </p>
        <p class="mt-1 mb-5 text-xs font-semibold">
          Images must be 1280px × 853px
        </p>
        <div
          v-if="form.slide3ImageURL !== null"
          class="w-full object-contain relative"
        >
          <img
            class="object-cover"
            height="268"
            width="356"
            :src="form.slide3ImageURL"
          />
          <button
            class="absolute -top-3 -right-3 lb-dialog-close-btn-x text-sm"
            @click.prevent="form.slide3ImageURL = null"
          >
            x
          </button>
        </div>

        <div v-else class="h-48 w-72 flex items-center justify-center border">
          <span class="flex flex-col items-center">
            <ImageIcon />
            No Image
          </span>
        </div>

        <input
          type="file"
          class="mt-5"
          @change="previewSlider3Image"
          accept="image/*"
        />
      </div>

            <!-- Slider 4 -->
      <div class="w-full xl:w-1/3 flex flex-col mb-5 px-3">
        <p class="mt-3">Slider 4</p>
        <p class="text-sm">
          Image will display as the third image in the unit slider
        </p>
        <p class="mt-1 mb-5 text-xs font-semibold">
          Images must be 1280px × 853px
        </p>
        <div
          v-if="form.slide4ImageURL !== null"
          class="w-full object-contain relative"
        >
          <img
            class="object-cover"
            height="268"
            width="356"
            :src="form.slide4ImageURL"
          />
          <button
            class="absolute -top-3 -right-3 lb-dialog-close-btn-x text-sm"
            @click.prevent="form.slide4ImageURL = null"
          >
            x
          </button>
        </div>

        <div v-else class="h-48 w-72 flex items-center justify-center border">
          <span class="flex flex-col items-center">
            <ImageIcon />
            No Image
          </span>
        </div>

        <input
          type="file"
          class="mt-5"
          @change="previewSlider4Image"
          accept="image/*"
        />
      </div>
      <!-- Slider 5 -->
      <div class="w-full xl:w-1/3 flex flex-col mb-5 px-3">
        <p class="mt-3">Slider 5</p>
        <p class="text-sm">
          Image will display as the third image in the unit slider
        </p>
        <p class="mt-1 mb-5 text-xs font-semibold">
          Images must be 1280px × 853px
        </p>
        <div
          v-if="form.slide5ImageURL !== null"
          class="w-full object-contain relative"
        >
          <img
            class="object-cover"
            height="268"
            width="356"
            :src="form.slide5ImageURL"
          />
          <button
            class="absolute -top-3 -right-3 lb-dialog-close-btn-x text-sm"
            @click.prevent="form.slide5ImageURL = null"
          >
            x
          </button>
        </div>

        <div v-else class="h-48 w-72 flex items-center justify-center border">
          <span class="flex flex-col items-center">
            <ImageIcon />
            No Image
          </span>
        </div>

        <input
          type="file"
          class="mt-5"
          @change="previewSlider5Image"
          accept="image/*"
        />
      </div>

      <!-- Unit Customer Details Content -->
      <p class="w-full mt-5 pt-5 text-lg font-bold border-t">
        Customer Details
      </p>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">First Name</label>
        <input
          v-model="form.customerFirstName"
          class="lb-admin-form-input-select rounded"
          type="text"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Last Name</label>
        <input
          v-model="form.customerLastName"
          class="lb-admin-form-input-select rounded"
          type="text"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Contact Number</label>
        <input
          v-model="form.customerContact"
          class="lb-admin-form-input-select rounded"
          type="text"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Email</label>
        <input
          v-model="form.customerEmail"
          class="lb-admin-form-input-select rounded"
          type="text"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <label class="w-full">Agent</label>
        <select
          v-model="form.customerAgent"
          :clearable="true"
          :searchable="true"
          :close-on-select="true"
          class="lb-admin-form-input-select rounded"
        >
          <option
            value=""
          >
            None
          </option>
          <option
            v-for="agent in agents"
            :key="agent.agentID"
            :value="agent.firstName"
          >
            {{agent.firstName }} {{agent.lastName }}
          </option>

        </select>
      </div>

      <!-- Unit Settings Content -->
      <p class="w-full mt-5 pt-5 text-lg font-bold border-t">Unit Settings</p>

      <div class="w-full lg:w-1/3 mt-5">
        <o-switch v-model="form.displayOnHomePage">
          Display Unit on Home Page
        </o-switch>
      </div>

      <div class="w-full lg:w-1/3 mt-5">
        <o-switch v-model="form.setGlobalDiscountPrice">
          Set unit's discount price globally
        </o-switch>
      </div>

      <!-- Page Content -->
      <div class="w-full mt-10 pt-5 border-t">
        <button
          v-if="!isEditMode"
          @click.prevent="addUnit"
          class="bg-primary px-4 py-2 text-white font-semibold rounded"
        >
          Add Unit
        </button>

        <button
          v-if="isEditMode"
          @click.prevent="updateUnit(unitID)"
          class="bg-primary px-4 py-2 text-white font-semibold rounded"
        >
          Update
        </button>

        <button
          v-if="isEditMode"
          @click.prevent="deleteUnit(unitID)"
          class="bg-red-600 ml-5 px-4 py-2 text-white font-semibold rounded"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";

import ImageIcon from "vue-material-design-icons/FileImage";

import {
  unitsCollection,
  dealsCollection,
  settingsCollection,
  profilesCollection
} from "../../firebase.js";
import isNil from "lodash/isNil";
import toInteger from "lodash/toInteger";
import {APP_TITLE, RENTAL_GUARANTEED_DEFAULT, OVERRIDE_ACTION_BUTTON_DEFAULT} from "@/Constants";

export default {
  name: "AdminUnitsList",
  props: {
    isPublished: Boolean,
  },
  components: {
    ImageIcon,
  },
  data: () => ({
    unitTypes: [],
    title: APP_TITLE,
    agents: [],
    // units: [],
    unitID: null,
    globals: [],

    mainImageData: {
      data: null,
      type: null,
    },
    slider2Data: {
      data: null,
      type: null,
    },
    slider3Data: {
      data: null,
      type: null,
    },
    slider4Data: {
      data: null,
      type: null,
    },
    slider5Data: {
      data: null,
      type: null,
    },

    form: {
      name: null,

      unitType: null,
      unitPrice: null,
      unitLevies: null,
      unitRates: null,
      unitGuaranteedRental: null,
      unitFloor: null,
      unitBedrooms: null,
      unitBathrooms: null,
      unitParking: null,
      unitStoreroom: null,
      isUnitAircon: false,
      unitAspect: null,
      unitView: null,
      unitInternalArea: null,
      unitExternalArea: null,
      unitTotalArea: null,

      customerFirstName: null,
      customerLastName: null,
      customerContact: null,
      customerEmail: null,
      customerAgent: '',

      mainImageURL: null,
      slide2ImageURL: null,
      slide3ImageURL: null,
      slide4ImageURL: null,
      slide5ImageURL: null,

      displayOnHomePage: true,
      isGuaranteedRental: false,
      isOverrideAction: false,
      unitRentalGuaranteed: RENTAL_GUARANTEED_DEFAULT || null,
      unitOverrideAction: null,
      unitOverrideActionButton: OVERRIDE_ACTION_BUTTON_DEFAULT || null,
      customIdViewButton: null,
      setGlobalDiscountPrice: true,
      isOneDayOnlyDiscount: false,
      oneDayOnlyDiscount: null,
      oneDayOnlyDiscountDateStart: null,
      oneDayOnlyDiscountDateFinish: null,

      status: "available",
    },
    unitParkingBay: null,
    unitGuaranteedRentalPrice: null,
    pendingStatuses: ['pending', 'pendingUnitDetails', 'pendingPayment'],
    agentStatuses: ['agent', 'agent-principal']
  }),
  created() {
    let unitTypes = [];
    this.units.forEach((doc) => {
      unitTypes.push({
        unitType: doc.type,
      });
    });
    this.unitTypes = unitTypes;

    profilesCollection
      .where("role", "in", this.agentStatuses)
      // .where("role", "==", "agent-principal")
      .get()
      .then((snap) => {
        let agents = [];
        snap.forEach((doc) => {
          agents.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            contact: doc.data().formatNationalContactNumber,
            created: doc.data().created,
            role: doc.data().role,
            order: doc.data().order,
            agentID: doc.id,
          });
        });
        this.agents = agents;
      });

    settingsCollection.orderBy("setOneDayOnlyDiscount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          setOneDayOnlyDiscount: doc.data().setOneDayOnlyDiscount,
        });
      });
      this.globals = globals;
    });

  },
  methods: {
    previewMainImage(e) {
      this.form.mainImageURL = null;
      this.mainImageData.data = e.target.files[0];
      this.mainImageData.type = e.target.files[0].type;

      this.imageUpload("mainImage");
    },
    previewSlider2Image(e) {
      this.form.slide2ImageURL = null;
      this.slider2Data.data = e.target.files[0];
      this.slider2Data.type = e.target.files[0].type;

      this.imageUpload("slider2Image");
    },
    previewSlider3Image(e) {
      this.form.slide3ImageURL = null;
      this.slider3Data.data = e.target.files[0];
      this.slider3Data.type = e.target.files[0].type;

      this.imageUpload("slider3Image");
    },
    previewSlider4Image(e) {
      this.form.slide4ImageURL = null;
      this.slider4Data.data = e.target.files[0];
      this.slider4Data.type = e.target.files[0].type;

      this.imageUpload("slider4Image");
    },
    previewSlider5Image(e) {
      this.form.slide5ImageURL = null;
      this.slider5Data.data = e.target.files[0];
      this.slider5Data.type = e.target.files[0].type;

      this.imageUpload("slider5Image");
    },
    imageUpload(imageName) {
      const randomNameString = this.randomString(10);
      const datetime = firebase.firestore.Timestamp.now().nanoseconds;
      const uploadImageName = `unit-images/${imageName}-${randomNameString}-${datetime}.jpeg`.replace(
        /\s/g,
        ""
      );

      var imageData;
      var imageMetaData;

      if (imageName === "mainImage") {
        imageData = this.mainImageData.data;
        imageMetaData = this.mainImageData.type;
      }

      if (imageName === "slider2Image") {
        imageData = this.slider2Data.data;
        imageMetaData = this.mainImageData.type;
      }

      if (imageName === "slider3Image") {
        imageData = this.slider3Data.data;
        imageMetaData = this.mainImageData.type;
      }
      if (imageName === "slider4Image") {
        imageData = this.slider4Data.data;
        imageMetaData = this.mainImageData.type;
      }
      if (imageName === "slider5Image") {
        imageData = this.slider5Data.data;
        imageMetaData = this.mainImageData.type;
      }

      const metadata = {
        contentType: imageMetaData,
      };

      const storageRef = firebase
        .storage()
        .ref(uploadImageName)
        .put(imageData, metadata);

      storageRef.on(
        "state_clickd",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Main Image Error",
            text: error.message,
          });
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (imageName === "mainImage") {
              this.form.mainImageURL = url;
            }

            if (imageName === "slider2Image") {
              this.form.slide2ImageURL = url;
            }

            if (imageName === "slider3Image") {
              this.form.slide3ImageURL = url;
            }
            if (imageName === "slider4Image") {
              this.form.slide4ImageURL = url;
            }
            if (imageName === "slider5Image") {
              this.form.slide5ImageURL = url;
            }
          });
        }
      );
    },
    randomString(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    getUnit(unitID) {
      unitsCollection
        .doc(unitID)
        .get()
        .then((doc) => {
          this.form.name = doc.data().name;
          this.form.unitType = doc.data().type;
          this.form.unitPrice = doc.data().price;
          this.form.unitLevies = doc.data().levies;
          this.form.unitRates = doc.data().rates;
          this.form.unitGuaranteedRental = doc.data().guaranteedRental;
          this.form.unitFloor = doc.data().floor;
          this.form.unitBedrooms = doc.data().bedrooms;
          this.form.unitBathrooms = doc.data().bathrooms;
          this.form.unitParking = doc.data().parking;
          this.form.unitStoreroom = doc.data().storeroom;
          this.form.isUnitAircon = doc.data().isAircon;
          this.form.unitAspect = doc.data().aspect;
          this.form.unitView = doc.data().view;
          this.form.unitInternalArea = doc.data().internalArea;
          this.form.unitExternalArea = doc.data().externalArea;
          this.form.unitTotalArea = doc.data().totalArea;
          this.form.mainImageURL = doc.data().mainImageURL;
          this.form.slide2ImageURL = doc.data().slide2ImageURL;
          this.form.slide3ImageURL = doc.data().slide3ImageURL;
          this.form.slide4ImageURL = doc.data().slide4ImageURL;
          this.form.slide5ImageURL = doc.data().slide5ImageURL;

          this.form.displayOnHomePage = doc.data().displayOnHomePage;
          this.form.isGuaranteedRental = doc.data().isGuaranteedRental;
          this.form.isOverrideAction = doc.data().isOverrideAction;
          this.form.isOneDayOnlyDiscount = doc.data().isOneDayOnlyDiscount;
          this.form.oneDayOnlyDiscount = doc.data().oneDayOnlyDiscount;
          (this.form.oneDayOnlyDiscountDateStart = doc.data().oneDayOnlyDiscountDateStart ? doc.data().oneDayOnlyDiscountDateStart.toDate() : null);
          (this.form.oneDayOnlyDiscountDateFinish = doc.data().oneDayOnlyDiscountDateFinish ? doc.data().oneDayOnlyDiscountDateFinish.toDate() : null);
          this.form.unitRentalGuaranteed = doc.data().unitRentalGuaranteed;
          this.form.unitOverrideAction = doc.data().unitOverrideAction;
          this.form.unitOverrideActionButton = doc.data().unitOverrideActionButton;
          this.form.customIdViewButton = doc.data().customIdViewButton;
          this.form.setGlobalDiscountPrice = doc.data().setGlobalDiscountPrice;

          this.form.customerFirstName = doc.data().customerFirstName;
          this.form.customerLastName = doc.data().customerLastName;
          this.form.customerContact = doc.data().customerContact;
          this.form.customerEmail = doc.data().customerEmail;
          this.form.customerAgent = doc.data().customerAgent;

          // Clean up.
          if (this.pendingStatuses.includes(doc.data().status))
            this.form.status = 'pending';
          else
            this.form.status = doc.data().status;

          this.unitParkingBay = doc.data().unitParkingBay;
          this.unitGuaranteedRentalPrice = doc.data().unitGuaranteedRentalPrice;

          this.unitID = doc.id;
          if (this.pendingStatuses.includes(doc.data().status) && doc.data().customerFirstName === '' ) {
            dealsCollection
              .where("unitId", "==", doc.id)
              .orderBy("modified", "desc")
              .limit(1)
              .get()
              .then((snapshot) => {
                snapshot.forEach((doc) => {
                  this.form.customerFirstName = doc.data().customerFirstName;
                  this.form.customerLastName = doc.data().customerLastName;
                  this.form.customerContact = doc.data().customerContact;
                  this.form.customerEmail = doc.data().customerEmail;
                  this.form.customerAgent = doc.data().customerAgent;
                });
              });
          }
        });
    },
    addUnit() {
      const unitData = {
        name: this.form.name,
        type: this.form.unitType,
        price: toInteger(this.form.unitPrice),
        levies: toInteger(this.form.unitLevies),
        rates: toInteger(this.form.unitRates),
        guaranteedRental: toInteger(this.form.unitGuaranteedRental),
        floor: this.form.unitFloor,
        bedrooms: toInteger(this.form.unitBedrooms),
        bathrooms: toInteger(this.form.unitBathrooms),
        parking: toInteger(this.form.unitParking),
        storeroom: toInteger(this.form.unitStoreroom),
        isAircon: this.form.isUnitAircon,
        aspect: this.form.unitAspect,
        view: this.form.unitView,
        internalArea: toInteger(this.form.unitInternalArea),
        externalArea: toInteger(this.form.unitExternalArea),
        totalArea: toInteger(this.form.unitTotalArea),

        customerHasAccount: false,

        mainImageURL: this.form.mainImageURL,
        slide2ImageURL: this.form.slide2ImageURL,
        slide3ImageURL: this.form.slide3ImageURL,
        slide4ImageURL: this.form.slide4ImageURL,
        slide5ImageURL: this.form.slide5ImageURL,

        displayOnHomePage: this.form.displayOnHomePage,
        isGuaranteedRental: this.form.isGuaranteedRental,
        isOverrideAction: this.form.isOverrideAction,
        isOneDayOnlyDiscount: this.form.isOneDayOnlyDiscount,
        oneDayOnlyDiscount: toInteger(this.form.oneDayOnlyDiscount),
        oneDayOnlyDiscountDateStart: this.form.oneDayOnlyDiscountDateStart,
        oneDayOnlyDiscountDateFinish: this.form.oneDayOnlyDiscountDateFinish,
        unitRentalGuaranteed: this.form.unitRentalGuaranteed,
        unitOverrideAction: this.form.unitOverrideAction,
        unitOverrideActionButton: this.form.unitOverrideActionButton,
        customIdViewButton: this.form.customIdViewButton,
        setGlobalDiscountPrice: this.form.setGlobalDiscountPrice,

        // === Status Options ===
        // available
        // pending
        // reserved
        // sold

        status: this.form.status,

        customerFirstName: this.form.customerFirstName,
        customerLastName: this.form.customerLastName,
        customerContact: this.form.customerContact,
        customerEmail: this.form.customerEmail,
        customerAgent: this.form.customerAgent,

        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        addedBy: this.user.profile.email,
        modifiedBy: this.user.profile.email,

        unitParkingBay: this.unitParkingBay,
      };

      const dealData = {
        customerFirstName: this.form.customerFirstName,
        customerLastName: this.form.customerLastName,
        customerContact: this.form.customerContact,
        customerEmail: this.form.customerEmail,
        customerAgent: this.form.customerAgent,

        customerAcceptedTerms: null,
        customerAcceptedTermsTimestamp: null,
        customerInitPurchaseTimestamp: null,
        customerDepositPurchaseTimestamp: null,

        status: this.form.status,

        reservationCancelledTimestamp: null,
        reservationCancelToken: null,

        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        addedBy: this.user.profile.email,
        modifiedBy: this.user.profile.email,
      };

      unitsCollection
        .add(unitData)
        .then((docRef) => {
          dealData.unitId = docRef.id;
          dealsCollection.add(dealData).then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Unit Updated",
            });
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Login Error",
            text: error.message,
          });
        });
    },
    updateUnit(unitID) {
      const unitData = {
        name: this.form.name,
        type: this.form.unitType,
        price: toInteger(this.form.unitPrice),
        levies: toInteger(this.form.unitLevies),
        rates: toInteger(this.form.unitRates),
        guaranteedRental: toInteger(this.form.unitGuaranteedRental),
        isGuaranteedRental: this.form.isGuaranteedRental,
        isOverrideAction: this.form.isOverrideAction,
        isOneDayOnlyDiscount: this.form.isOneDayOnlyDiscount,
        oneDayOnlyDiscount: toInteger(this.form.oneDayOnlyDiscount),
        oneDayOnlyDiscountDateStart: this.form.oneDayOnlyDiscountDateStart,
        oneDayOnlyDiscountDateFinish: this.form.oneDayOnlyDiscountDateFinish,
        unitRentalGuaranteed: this.form.unitRentalGuaranteed,
        unitOverrideAction: this.form.unitOverrideAction,
        unitOverrideActionButton: this.form.unitOverrideActionButton,
        customIdViewButton: this.form.customIdViewButton,
        floor: this.form.unitFloor,
        bedrooms: toInteger(this.form.unitBedrooms),
        bathrooms: toInteger(this.form.unitBathrooms),
        parking: toInteger(this.form.unitParking),
        storeroom: toInteger(this.form.unitStoreroom),
        isAircon: this.form.isUnitAircon,
        aspect: this.form.unitAspect,
        view: this.form.unitView,
        internalArea: toInteger(this.form.unitInternalArea),
        externalArea: toInteger(this.form.unitExternalArea),
        totalArea: toInteger(this.form.unitTotalArea),
        customerFirstName: this.form.customerFirstName,
        customerLastName: this.form.customerLastName,
        customerContact: this.form.customerContact,
        customerEmail: this.form.customerEmail,
        customerAgent: this.form.customerAgent,

        mainImageURL: this.form.mainImageURL,
        slide2ImageURL: this.form.slide2ImageURL,
        slide3ImageURL: this.form.slide3ImageURL,
        slide4ImageURL: this.form.slide4ImageURL,
        slide5ImageURL: this.form.slide5ImageURL,

        displayOnHomePage: this.form.displayOnHomePage,
        setGlobalDiscountPrice: this.form.setGlobalDiscountPrice,

        status: this.form.status,
        unitReservedTime: null,

        modified: firebase.firestore.Timestamp.now(),
        modifiedBy: this.user.profile.email,

        unitParkingBay: this.unitParkingBay,
      };

      const dealData = {
        customerFirstName: this.form.customerFirstName,
        customerLastName: this.form.customerLastName,
        customerContact: this.form.customerContact,
        customerEmail: this.form.customerEmail,
        customerAgent: this.form.customerAgent,

        status: this.form.status,

        modified: firebase.firestore.Timestamp.now(),
        modifiedBy: this.user.profile.email,
      };

      unitsCollection
        .doc(unitID)
        .update(unitData)
        .then(() => {
          dealsCollection
            .where("unitId", "==", unitID)
            .limit(1)
            .get()
            .then((query) => {
              if (query.docs && query.docs.length > 0) {
                const deal = query.docs[0];
                deal.ref.update(dealData);
              }
              this.$notify({
                group: "global",
                type: "success",
                title: "Unit Updated",
              });
            });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Update error",
            text: error.message,
          });
        });
    },
    deleteUnit(unitID) {
      var userConfirmedDelete = confirm(
        "Are you sure you want to delete this unit?"
      );

      if (userConfirmedDelete) {
        unitsCollection
          .doc(unitID)
          .delete()
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Unit deleted",
            });

            this.$router.replace({ name: "admin-units" });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: error,
            });
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "units",
    }),
    isEditMode() {
      if (!isNil(this.$route.params.id)) {
        return true;
      }
      return false;
    },
    unitTypeList: function() {
      const uniqueUnitTypes = [];
      for(let i =0; i < this.unitTypes.length; i++) {
        if(uniqueUnitTypes.indexOf(this.unitTypes[i].unitType) === -1) {
          uniqueUnitTypes.push(this.unitTypes[i].unitType)
        }
      }
      const uniqueUnitType = uniqueUnitTypes.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      });

      return uniqueUnitType

    },
  },
  mounted() {
    if (!isNil(this.$route.params.id)) {
      const unitID = this.$route.params.id;
      this.getUnit(unitID);
    }
  },
};
</script>
